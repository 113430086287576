import React, { useState } from 'react'
import landingBack from '../assets/images/landing-banner.png';
import '../assets/css/home.css';
import { useNavigate } from 'react-router-dom';
import doctorsImg from '../assets/images/login-page-banner.svg';
import Login from '../pages/Login';
import Email from '../pages/Login/SignUp';

const Home = () => {
    const navigate = useNavigate();
    const [mobile,setMobile]= useState(true)

  
    return (
        <div className='login-main'>
        <div className="content-wrapper">
          <div className="banner-part">
            <div className="banner-content">
              <img src={doctorsImg} alt="doctors-logo" />
              <div className="text-part">
                <h4>Over 500+ Doctors & Physiotherapists</h4>
                <p>Experience the future of physiotherapy with VirtueLife. Access personalized exercise programs, track your progress, and engage with your physiotherapist remotely. It's convenient, effective, and tailored to your needs.</p>
              </div>
            </div>
          </div>
          <div className="form-part">

             

          {mobile === true ?  <Login mobile={mobile} setMobile={setMobile}/> : <Email  setMobile={setMobile}/>}
          </div>
          </div>
          </div>
    )
}

export default Home
