import React from 'react'
import '../../assets/css/home.css'
import Layout from '../../layout'
import TopBar from '../../components/TopBarNavigation'
import LeftIcon from '../../assets/images/left.svg'
import { useLocation, Link, useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap';
import Auth from '../../libs/auth'
import { deletes, get, getAuthConfig, post } from '../../libs/http-hydrate';
import swal from "sweetalert";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from 'react-bootstrap';
import doneImg from '../../assets/images/Opps.svg'; // Import the image
import { useState } from 'react'

const Settings = () => {
    const navigate = useNavigate();
    const [show, setShowModal] = useState(false);
    async function deleteMyAccount(){
        try {
            const data = await deletes(`/delete-account?type=patient&permenant=1`);
            if (data?.status === 200) {
                toast.success("Your Account deleted Successfully")
                setTimeout(() =>{
                    Auth.logout();
                    navigate(("/login"))
                },2000)
            }
          } catch (error) {
          }
    }
    function onHide(){
        setShowModal(false)
    }
  return (
    <Layout>
        <ToastContainer />
 <TopBar  title={"Settings"}   tab={"Profile"} tabChild={"Settings"} />
      <div className='main-content-part'>
        <div className='common-link-card-part'>
            <Link to="" className='link-card' onClick={(e) =>{
                e.preventDefault();
                setShowModal(true)
            }}>
                <p className='text'>Help Us</p>
                <div className='img-block'>
                    <img src={LeftIcon} alt='left'/>
                </div>
            </Link>
            <a href="https://recureme.com/" target="_blank" rel="noopener noreferrer" className='link-card'>
                <p className='text'>About Us</p>
                <div className='img-block'>
                    <img src={LeftIcon} alt='left'/>
                </div>
                </a>
            <a href="https://virtuelife.ai/privacy/" target="_blank" rel="noopener noreferrer" className='link-card'>
                <p className='text'>Privacy & Policy</p>
                <div className='img-block'>
                    <img src={LeftIcon} alt='left'/>
                </div>
            </a>
            <a href="https://virtuelife.ai/terms/" target="_blank" rel="noopener noreferrer" className='link-card'>
                <p className='text'>Terms</p>
                <div className='img-block'>
                    <img src={LeftIcon} alt='left'/>
                </div>
                </a>
       
                <a href="https://virtuelife.ai/refund/" target="_blank" rel="noopener noreferrer" className='link-card'>
                <p className='text'>Refund</p>
                <div className='img-block'>
                    <img src={LeftIcon} alt='left'/>
                </div>
                </a>
            <div className='butoon-part mt-5'>
            <button className="white-shdow-btn border-0"  onClick={(e)=>{
                                        e.preventDefault();
                                        Auth.logout();
                                        navigate("/login")
                                    }}>Logout</button>
            <button className="white-shdow-btn border-0" onClick={(e) =>{
                e.preventDefault();
                swal({
                    title: "Are you sure?",
                    text: `Are you sure to delete this account , your data may be lost ?`,
                    icon: "warning",
                    dangerMode: true,
                    buttons: ["No", "Yes"],
                  }).then(async (willDelete) => {
                    if (willDelete) {
                     deleteMyAccount()
                    } else {
                    }
                  });
            }}>Delete Account</button>
            </div>
           
        </div>
        </div>


        <Modal
      show={show}
      onHide={onHide}
      size="md"
      className="complete-modal"
      centered
    >
      <Modal.Header>
        <div className="top-heading w-100 d-flex justify-content-end text-end">
          <button className="btn btn-transpatent" onClick={onHide}>
            X
          </button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="content-block text-center">
          <img src={doneImg} height={60} width={60} alt="modal-video" />
        </div>
        <div className="bottom-block text-center">
          <div
            className="workout-desc body-content"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingTop: "25px",
            }}
          >
            <p>Contact Us!</p>
            <span className='text-secondary'>If you have any questions or need support, please reach out to us at:</span>
            <span className='my-3'>
            <strong>Email:</strong> <a href="mailto:support@virtuelife.ai" style={{color:"black"}}>support@virtuelife.ai</a>

            </span>
            <span className='text-secondary' style={{ fontSize: '12px', marginLeft: '10px' }}>Our support team is available to assist you with any inquiries you may have. We strive to provide the best possible service and support to our users. </span>
            <button
              className="theme-button btn btn-primary mt-3"
              style={{ minWidth: "300px", padding: "14px" }}
              onClick={onHide}
            >
              Close
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </Layout>
  )
}

export default Settings
