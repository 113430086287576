import React, { useEffect, useState } from "react";

import Layout from "../layout";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { get, getAuthConfig, post } from "../libs/http-hydrate";
import Auth from "../libs/auth";
import doneImg from "../assets/images/done-icon.svg";
import Modal from "react-bootstrap/Modal";
const loadScript = (src) => {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
};

const App = () => {
  const location = useLocation()
  const user = Auth.getCurrentUser()
  const navigate = useNavigate()
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subscribeId,setSubscribeId] = useState("")
  const [completeWorkout, setCompleteWorkout] = useState(false);
  const [contactInformation, setContactInformation] = useState({
    emergencyNumber: "",
    address: "",
    state: "",
    city: "",
    country: "",
    pincode: "",
  });

  const [data, setData] = useState(() => {
    // Retrieve the item from localStorage
    const storedData = localStorage.getItem("user");
    if (storedData) {
      try {
        // Parse it to an object and return as initial state
        return JSON.parse(storedData);
      } catch (error) {
        console.error(
          "Error parsing notificationData from localStorage:",
          error
        );
        // Return a fallback initial state if parsing fails
        return {};
      }
    }
    // Return an initial state if nothing is stored in localStorage
    return {};
  });

  const [useResidenceAddress, setUseResidenceAddress] = useState(false);

  const [countryLoading, setCountryLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [countriesFetched, setCountriesFetched] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [country, setCountry] = useState("");
  let [state, setstate] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const checkFormValidity = () => {
      const isBillingAddressComplete = useResidenceAddress
        ? data?.address?.street &&
          data?.address?.city &&
          data?.address?.state &&
          data?.address?.country &&
          data?.address?.pincode
        : contactInformation.street &&
          contactInformation.city &&
          contactInformation.state &&
          contactInformation.country &&
          contactInformation.pincode;
      setIsFormValid(isBillingAddressComplete);
    };

    checkFormValidity();
  }, [useResidenceAddress, contactInformation, data]);

  async function getCountrie() {
    if (countriesFetched) {
      return; // Countries have already been fetched
    }
    try {
      setCountryLoading(true);
      const data = await get("/country", getAuthConfig());
      setCountries(data?.data?.data);
      setCountriesFetched(true); // Update the state to indicate countries have been fetched
    } catch (error) {
      console.error(error);
    } finally {
      setCountryLoading(false);
    }
  }

  async function handleCountryChange(event) {
    const countryCode = event.target.value;
    setCountry(countryCode);

    if (countryCode) {
      try {
        setStateLoading(true);
        const response = await get(
          `/state?countryCode=${countryCode}`,
          getAuthConfig()
        );
        setStates(response?.data?.data);
        setstate("");
        setContactInformation((p) => ({ ...p, state: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setStateLoading(false);
      }
    } else {
      setStates([]);
      setstate("");
    }
  }

  async function handleStateChange(event) {
    const stateCode = event.target.value;
    setstate(stateCode);

    if (country && stateCode) {
      try {
        setCityLoading(true);
        const response = await get(
          `/city?countryCode=${country}&stateCode=${stateCode}`,
          getAuthConfig()
        );
        setCities(response?.data?.data);
        setContactInformation((p) => ({ ...p, city: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setCityLoading(false);
      }
    } else {
      setCities([]);
    }
  }

  async function displayRazorpay() {
    setLoading(true);  // Start loading before the script loads
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) {
      alert('Razorpay SDK failed to load. Are you online?');
      setLoading(false);
      return;
    }
    const nowTime = new Date();
    const date = nowTime.getTime();
    
    const userData = {currency:"INR"}
    try {
    const data = await post('/patientPaymentWeb/create-order',userData ,getAuthConfig());
    const result =  data.data
  
    if (!result.order) {
      alert('Failed to create subscription. Please try again.');
      return;
    }
  
    const options = {
      key: 'rzp_live_opK2gM7NYmZGRs', // Enter the Key ID generated from the Razorpay dashboard
      order_id: result.order.id,
      amount: result.order.amount,
      currency: result.order.currency,
      name: "Lauruss",
      description: "Subscription for Premium Plan",
      image: "https://virtuelife.s3.ap-south-1.amazonaws.com/RecureMe/VirtueLifelogo.svg",
      handler: async function (response) {
        const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = response;
        // Call your backend to verify the payment and activate the subscription
        setSubscribeId(razorpay_payment_id)
        const verifyUrl = '/patientPaymentWeb/verify-payment';
        const verifyData = { orderId: razorpay_order_id, paymentId: razorpay_payment_id, signature: razorpay_signature,amount:9900,currency:"INR" };
        const verifyResponse = await post(verifyUrl,verifyData ,getAuthConfig());
        const verifyResult = verifyResponse?.data
        if (verifyResult.valid) {
          setCompleteWorkout(true);
          // alert('Subscription successful');
          // Handle further actions like updating UI or database accordingly
        } else {
          alert('Subscription verification failed');
        }
      },
      prefill: {
        name: user?.name,
        email: user?.email,
        contact: user?.mobile_number
      },
      theme: {
        color: "#23bee3"
      }
    };
  
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }catch (error) {
      console.error('Error creating subscription:', error);
      alert('Failed to process your request. Please try again.');
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
    <Layout>

    <div className="payment">
          <div className="text-center mt-5">
            <h5 className="title"> Payment Information</h5>
            <p>One more step before closing more revenue with RecureMe.</p>
          </div>
          <div className="d-flex justify-content-center">
            <div className="card shadow m-4 w-75 bg-white border-0 p-3 mb-5">
                <div className="form-data p-2" style={{ maxWidth: "100%" ,width:"100%"}}>
                  {/* <h5 className="text-start  py-3">Billing address</h5> */}

                  <Row className="g-4 w-100">
                    <Col lg="12">
                      <div className="filed">
                        <label>Your Name <span className="text-danger">*</span></label>
                        <input
                          type="text"
                          placeholder="First Name"
                          className="form-control"
                          disabled
                          value={data?.name}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>

      
                {error && (
                  <div className="text-danger text-center mb-5">{error}</div>
                )}

                <div className="buttonPart mt-3 d-flex justify-content-center mb-5">
                  <button
                    className="theme-button text-white border-0"
                    disabled={loading}
                    onClick={displayRazorpay}
                  >
  {loading ? "Processing..." : "Pay with Razorpay"}
                  </button>
                </div>
            </div>
          </div>
        </div>
      {/* <div className="container text-center mt-4">
                <button onClick={displayRazorpay} className="btn btn-primary">Pay with Razorpay</button>
      </div> */}
      <Modal
          show={completeWorkout}
          onHide={() => setCompleteWorkout(false)}
          size="md"
          className="complete-modal"
        >
          <Modal.Body>
            <div className="top-heading text-end">
              <button
                className="btn btn-transpatent"
                onClick={() => setCompleteWorkout(false)}
              >
                X
              </button>
            </div>
            <div className="content-block text-center">
              <img src={doneImg} height={350} width={400} alt="modal-video" />
            </div>
            <div className="bottom-block text-center">
              <div
                className="workout-desc body-content"
                style={{
                  display: "flex",
                  "align-items": "center",
                  "justify-content": "center",
                  "flex-direction": "column",
                  "padding-top": "25px",
                }}
              >
                <p>Payment Done</p>

                <span>Thank you for Choosing us</span>

                <button className="btn btn-outline-secondary my-3">
                  Order Id : {subscribeId}
                </button>

                <button
                  className="theme-button btn btn-primary"
                  style={{ minWidth: "300px", padding: "14px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard");
                  }}
                >
                  Done
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
    </Layout>
    </>
  );
};

export default App;
