import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/login.css";
import doctorsImg from "../../assets/images/login-page-banner.svg";
import { AiOutlineClose } from "react-icons/ai";
import IndiaFlag from "../../assets/images/country-flag.svg";
import { BiSolidDownArrow } from "react-icons/bi";
import { Modal, Badge } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import TruecalloerLogo from "../../assets/images/Truecaller_Logo.png";
import ReactFlagsSelect from "react-flags-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { post, put } from "../../libs/http-hydrate";
import Auth from "../../libs/auth";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FieldValidationError from "../../components/common/FieldValidation";
import logo from "../../assets/images/docpy.png";

const Email = ({ mobile, setMobile }) => {
  const navigate = useNavigate();
  const [showModal, setModalShow] = useState(false);
  const [selected, setSelected] = useState("IN");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [mobile,setMobile]=useState("")
  const [otpDigits, setOtpDigits] = useState(["", "", "", "", "", ""]); // Array to store individual OTP digits
  const otpInputRefs = useRef([]);
  const closeModal = () => setModalShow(false);
  const [isLoading, setLoading] = useState(false); // Add a loading state
  const [isLoadingVer, setLoadingVer] = useState(false); // Add a loading state
  const [timerSeconds, setTimerSeconds] = useState(15 * 60); // 30 minutes in seconds
  const [isTimerRunning, setTimerRunning] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const emailREF = useRef(null);

  const [otpError, setOTPError] = useState("");
  const submitOTP = () => {
    navigate("/dashboard");
  };
  const resetTimer = () => {
    setTimerSeconds(15 * 60); // Reset timer to 15 minutes
    setTimerRunning(true); // Start the timer
  };
  const handleFieldError = (fieldRef) => {
    const inputField = fieldRef?.current?.querySelector("input");
    if (inputField) {
      // inputField.classList.add('field-error'); // Add the error class to the input field
      inputField.focus(); // Focus on the input field
      fieldRef.current.scrollIntoView({ behavior: "smooth" });
    } else {
      const selectField = fieldRef?.current?.querySelector("select");

      if (selectField) {
        selectField.classList.add("field-error"); // Add the error class to the select field
        fieldRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  async function signUpWithEmail() {
    // Check for null or empty email, password, and confirmPassword
    if (!email) {
      setEmailError(true);
      handleFieldError(emailREF);
      return;
    }

    setTimerSeconds(15 * 60);

    // Email format validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      handleFieldError(emailREF);

      // toast.error("Please enter a valid email address.");
      return;
    }

    setLoading(true);
    const formdata = new FormData();
    formdata.append("email", email);
    formdata.append("type", "patient");
    formdata.append("device", "Web")

    // if(mobile != ""){
    //     formdata.append("phone", "+" + mobile)

    // }
    try {
      const response = await post("/LoginEmail", formdata)
        .then((data) => {
          if (data?.status === 200) {
            // toast.success("Verification code sended to the your email account ");
            setModalShow(true);
            setTimerRunning(true);
            setLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.err);
        });
    } catch (error) {
      toast.error(error);
    }
  }

  async function verifyOTP() {
    const isAllFieldsFilled = otpDigits.every((digit) => digit !== "");
    if (isAllFieldsFilled) {
      const otp = otpDigits.join(""); // Convert the array of digits to a single OTP string
    } else {
      // Handle the case when all fields are not filled
      // Maybe show an error message or take appropriate action
      setOTPError("Please Add Correct OTP");
      return false;
    }
    const formdata = new FormData();

    formdata.append("type", "patient");
    formdata.append("email", email);
    formdata.append("otp", otpDigits.join(""));
    formdata.append("device", "Web")

    try {
      setLoadingVer(true);

      const response = await post("/verify-email-otp", formdata)
        .then((data) => {
          if (data?.status === 200) {
            localStorage.setItem(
              "user",
              JSON.stringify({
                _id: data?.data?.patient?._id,
                email: data?.data?.patient?.email,
                mobile_number: data?.data?.patient?.phone,
                token: data?.data?.patient?.token,
                profile: data?.data?.patient?.profile_image,
                address: data?.data?.patient?.address,
                loc: data?.data?.patient?.loc,
                gender: data?.data?.patient?.gender,
                name:data?.data?.patient?.name
              })
            );

            if (data?.data?.is_verified === true) {
              navigate("/dashboard");
            } else {
              navigate("/profile", {
                state: {
                  name: data?.data?.patient?.name,
                  phone: data?.data?.patient?.phone,
                },
              });
            }
          }
        })
        .catch((err) => {
          setOtpDigits(["", "", "", "", "", ""]); // Reset OTP digits

          setOTPError(err?.response?.data?.err);
        })
        .finally(() => {
          setLoadingVer(false); // Set loading to false after API call completion
        });
    } catch (error) {}
  }
  useEffect(() => {
    let interval;

    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimerSeconds((prevSeconds) => {
          if (prevSeconds === 0) {
            // Timer has reached 0, do something here
            setTimerRunning(false);
            // Maybe close the modal or take any necessary action
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval); // Clear the interval when the component unmounts
  }, [isTimerRunning]);

  // Handle input change for each OTP digit
  const handleOtpChange = (index, value) => {
    // If the user enters a non-digit value or clears the input, set the corresponding digit to an empty string
    const newValue = /^\d$/.test(value) ? value : "";
    setOtpDigits((prevDigits) => {
      const newDigits = [...prevDigits];
      newDigits[index] = newValue;
      return newDigits;
    });

    // Move to the next input field if a digit is entered
    if (/^\d$/.test(value) && otpInputRefs.current[index + 1]) {
      otpInputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text/plain").trim(); // Get the pasted text

    // Split the pasted text into individual characters
    const otpCharacters = pastedText.split("");

    // Loop through the OTP input elements and assign characters
    otpCharacters.forEach((character, index) => {
      if (index < otpDigits.length) {
        // Update the OTP digit at the current index
        handleOtpChange(index, character);
      }
    });
  };
  const handleBackspace = (index, e) => {
    if (e.key === "Backspace" && index > 0 && !otpDigits[index]) {
      const newOtpDigits = [...otpDigits];
      newOtpDigits[index - 1] = ""; // Clear the previous box
      setOtpDigits(newOtpDigits); // Update state if using React hooks or similar state management
      otpInputRefs.current[index - 1].focus(); // Move focus to previous input
    } else if (e.key === "Enter") {
      // Trigger OTP verification when Enter key is pressed
      const isAllFieldsFilled = otpDigits.every((digit) => digit !== "");
      if (isAllFieldsFilled) {
        verifyOTP();
      } else {
        // Handle the case when all fields are not filled
        setOTPError("Please fill all the OTP digits");
      }
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default action to avoid form submission
      resetTimer();
      signUpWithEmail(); // Trigger OTP sending logic when Enter is pressed
    }
  };
  return (
    <>
      <ToastContainer />
      {/*    
          <button className='close-btn'  onClick={(e) =>{
                navigate("/login")
            }}>
            <AiOutlineClose size={28} />
          </button> */}
      <div className="content">
        <div className="w-100 text-center mobile-logo">
          <img src={logo} alt="docpy-logo" width={180} height={60} />
        </div>
        <div className="header-part">
          <h3>Enter your Email </h3>
          <p>We will send you a confirmation code</p>
          <div className="d-flex">
            <div className="px-2">
              <label style={{ color: "#23BEE3", cursor: "pointer" }}>
                <input
                  type="radio"
                  name="loginOption"
                  value="email"
                  checked={!mobile}
                  onChange={() => setMobile(false)}
                />
                &nbsp; Email ?
              </label>
            </div>
            <div className="px-2">
              <label style={{ color: "#23BEE3", cursor: "pointer" }}>
                <input
                  type="radio"
                  name="loginOptionMo"
                  value="email"
                  checked={mobile}
                  onChange={() => setMobile(true)}
                />
                &nbsp; Mobile ?
              </label>
            </div>
          </div>

          {/* <p><a style={{color:"#23BEE3",cursor:"pointer"}} onClick={(e) =>{setMobile(true)}}>Want to login with Mobile Number?</a></p> */}
        </div>
        <div className="middle-form  m-0 form-data full-width-data">
          <div className=" filed w-100" ref={emailREF}>
            {/* ... (rest of the JSX) ... */}
            <input
              type="email"
              placeholder="Enter your Email"
              value={email}
              className={`input-field w-100 ${emailError ? "field-error" : ""}`}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(false);
              }}
              onKeyDown={handleKeyPress} // Add the key press handler here
            />
          </div>

          {emailError === true ? (
            <FieldValidationError message="Please provide valid email" />
          ) : (
            ""
          )}

          <p className="info-text">
            By signing up you are agree to our{" "}
            <a
              href="https://virtuelife.ai/terms/"
              target="_blank"
              rel="noreferrer"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href="https://virtuelife.ai/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </p>
        </div>
        <div className="action-part">
          <button
            className="btn btn-orange"
            disabled={isLoading}
            onClick={(e) => {
              e.preventDefault();
              resetTimer();
              signUpWithEmail();
            }}
          >
            {isLoading === true ? "Sending Authentication Code" : "Send Authentication Code"}
          </button>

          {/* <div className="divider">
                <span className='line'></span>
                <p className='text'>OR</p>
              </div>
              <button className="btn btn-orange outline" onClick={() => {
                  navigate("/login")
                }}>
                  SignUp / Login  With Mobile
                </button> */}
        </div>

        <Modal show={showModal} onHide={closeModal} centered>
          <Modal.Body style={{ border: "none" }}>
            <div className="content-modal">
              <div className="top-btn-part">
                <button className="btn close-btn" onClick={closeModal}>
                  <AiOutlineClose size={20} color="#000" />
                </button>
              </div>
              <div className="modal-inside-content">
                <h5 className="heading">Authentication Code Sent to your Email</h5>
                <p className="desc-text">
                  6 digit Authentication Code has sent your Email address
                </p>
                <p className="mobileno">
                  {email}{" "}
                  {/* <Badge pill className="edit-badge">
                    Edit
                  </Badge> */}
                </p>
                <div className="otp-input-group" onPaste={handlePaste}>
                  {otpDigits.map((digit, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength={1}
                      value={digit}
                      autoFocus={index === 0}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      onKeyDown={(e) => handleBackspace(index, e)}
                      ref={(el) => (otpInputRefs.current[index] = el)} // Store refs to OTP input elements
                    />
                  ))}
                </div>
                {otpError && <FieldValidationError message={otpError} />}
                <button className="btn-continue" onClick={verifyOTP}>
                  Continue
                </button>
                <span className="notreceive-text">
                  Haven't you received the SMS?
                </span>

                <button
                  className="resend-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    signUpWithEmail();
                    resetTimer(); // Call resetTimer to restart the 15-minute countdown
                  }}
                  disabled={timerSeconds !== 0} // Disable button until timer reaches 0
                  style={{
                    // Prevents hover and click
                    cursor: timerSeconds !== 0 ? "not-allowed" : "pointer",
                  }}
                >
                  {!isLoading ? "Resend Code" : "Sending code"}
                </button>
                <p className="timer">
                  {timerSeconds > 0
                    ? `${Math.floor(timerSeconds / 60)}:${(timerSeconds % 60)
                        .toString()
                        .padStart(2, "0")}`
                    : "You can now resend the code"}
                </p>
                {/* <button className='resend-btn' 
              disabled={timerSeconds > 0}
              onClick={(e) =>{
                e.preventDefault();
                signUpWithEmail()              }}> {!isLoading ? "Resend Code" : "Sending code"}</button>
     <p className="timer">{`${String(Math.floor(timerSeconds / 60)).padStart(2, '0')}:${(timerSeconds % 60)
  .toString()
  .padStart(2, '0')}`}</p> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Email;
