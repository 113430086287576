import React from 'react'
//import logo from '../assets/images/docpy.png'
import logo from '../assets/images/patientLogo.svg'
import '../assets/css/sidebar.css'
import logout from '../assets/images/signOut.svg'
import home from '../assets/images/home.svg'
import homeActive from '../assets/images/homeActive.svg'
import community from '../assets/images/community.svg';
import communityActive from '../assets/images/community-active.svg';
import excersize from '../assets/images/excersize.svg';
import excersizeActive from '../assets/images/excersize-active.svg';
import appointment from '../assets/images/appointment.svg';
import appointmentActive from '../assets/images/appointment-active.svg';
import { useLocation, Link, useNavigate } from 'react-router-dom'
import Auth from '../libs/auth'

const Sidebar = () => {
  const location = useLocation();
  const activePage = location.pathname.replace('/', '');
  const navigate = useNavigate();

  const handleLogout = () => {

      Auth.logout();
      navigate("/login")
  }

  return (
    <div className='sidebar-main'>
      <div className="logo-part">
        <img src={logo} alt="docpy-logo" width={180} height={70} />
      </div>
      <div className="nav-part">
        <div className="top-navpart">
          <Link
            to='/dashboard'
            style={{ textDecoration: "none", color: "inherit" }} 
            className={` ${activePage === 'dashboard' ? 'nav-btn active' : 'nav-btn'}`}
            >
              <img src={activePage === 'dashboard' ? homeActive : home} alt="" className='icon' />
              <p className='text'>Home</p>
          </Link>
          <Link
            to='/exercises'
            style={{ textDecoration: "none", color: "inherit" }} className={`${activePage === 'exercises' || activePage === 'excersiceList' || activePage === 'workout' || activePage === 'difficult' ? 'nav-btn active' : 'nav-btn'}`}>
              <img src={activePage === 'exercises' || activePage === 'excersiceList' || activePage === 'workout' || activePage === 'difficult' ? excersizeActive : excersize} alt="" className='icon' />
              <p className='text'>Exercises</p>
          </Link>
          <Link
            to='/appointment'
            style={{ textDecoration: "none", color: "inherit" }} className={`${activePage === 'appointment' || activePage === 'doctorDetails' || activePage === 'bookAppointment'   ? 'nav-btn active' : 'nav-btn'}`}>
              <img src={activePage === 'appointment' || activePage === 'doctorDetails' || activePage === 'bookAppointment'  ? appointmentActive : appointment} alt="" className='icon' />
              <p className='text'>Appointment</p>
          </Link>
          <Link
            to='/community'
            style={{ textDecoration: "none", color: "inherit" }} className={`${activePage === 'community' || activePage === 'communityDetails' ? 'nav-btn active' : 'nav-btn'}`}>
              <img src={activePage === 'community'  || activePage === 'communityDetails' ? communityActive : community} alt="" className='icon' />
              <p className='text'>Community</p>
          </Link>
        </div>
        <div className="bottom-logoutpart">
          <button className='logout-btn' onClick={() => handleLogout()}>
            <img src={logout} alt="logout-icon" className='icon' />
            <p className='text'>Logout</p>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
