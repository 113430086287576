import React, { useEffect, useState } from "react";
import "../../assets/css/communityCard.css";
import Layout from "../../layout";
import TopBar from "../../components/TopBarNavigation";
import BGImage from "../../assets/images/suggestion-card-bg.svg";
import Like from "../../assets/images/like.svg";
import Comment from "../../assets/images/CommentCommunity.svg";
import ProfImg from "../../assets/images/user.svg";
import ProfileImg from '../../assets/images/Ellipse 179.svg'
import SendButton from '../../assets/images/send.svg';
import { useLocation } from "react-router-dom";
import Auth from "../../libs/auth";
import PatientDummy from "../../assets/images/defaultpatient@3x.png"
import { get, getAuthConfig, post } from "../../libs/http-hydrate";
import moment from "moment";
import { Modal } from "react-bootstrap";
import LikeComment from "../../assets/images/LikeCommunity.svg"
import LikeCommentFill from "../../assets/images/FillLikePatient.svg"
import { useRef } from "react";
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
const CommunityCardDetails = () => {
  const firstCommentRef = useRef(null);
  const location = useLocation();
  const user = Auth?.getCurrentUser();
  const [communityData, setCommunityData] = useState({});
  const [commentList, setCommentList] = useState({
    items: [],
    has_more: false
  })
  const [commentPage, setCommentPage] = useState(1);
  const [commentText, setCommentText] = useState("");
  const [selectedComment, setSelectedComment] = useState({
    commentID: "",
    replyText: ""
  })
  const [ReplyList, setReplyList] = useState({
    items: [],
    has_more: false
  })
  const [ReplyPage, setReplyPage] = useState(1);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  useEffect(() => {
    if (location?.state?.comunity) {
      setCommunityData(location?.state?.comunity)
    }
  }, [location?.state?.comunity])
  useEffect(() => {
    if (communityData?._id) {
      getCommentListing()
    }
  }, [communityData?._id, commentPage])
  useEffect(() =>{
    if(selectedComment?.commentID){
      ReplyListing(selectedComment?.commentID)
    }
  },[ReplyPage])
  async function likeCommunity(id, unlike) {
    try {
      let url;
      if (unlike) {
        url = `/likeCommunity?communityId=${id}&unlike=true`
      }
      else {
        url = `/likeCommunity?communityId=${id}`;
      }
      const data = await get(url, getAuthConfig());
      if (data?.status === 200) {
        if (unlike) {

          setCommunityData((p) => ({ ...p, likeByYou: false, likeCount: communityData?.likeCount - 1 }))
        }
        else {
          setCommunityData((p) => ({ ...p, likeByYou: true, likeCount: communityData?.likeCount + 1 }))
        }

      }
    } catch (error) {
    }
  }
  async function getCommentListing() {
    try {
      const data = await get(`/comment?communityId=${communityData?._id}&pageNumber=${commentPage}`, getAuthConfig());
      if (data?.status === 200) {

        if (commentPage > 1) {
          setCommentList((p) => ({ ...p, items: commentList?.items.concat(data?.data?.comments) }));
        } else {
          setCommentList((p) => ({ ...p, items: data?.data?.comments }));
        }
        setCommentList((e) => ({ ...e, has_more: data?.data?.has_more }))
      }
    } catch (error) {
    }
  }
  async function sendAcomment() {
    try {
      const formdata = new FormData();
      formdata.append("communityId", communityData?._id);
      formdata.append("text", commentText);
      const data = await post(`/comment`, formdata, getAuthConfig());
      if (data?.status === 200) {
        setCommunityData((p) => ({ ...p, commentCount: communityData?.commentCount + 1 }))
        if (data?.data?.comment) {
          // Add the new comment to the top of the existing comments
          setCommentList((p) => ({
            ...p,
            items: [data?.data?.comment, ...commentList?.items],
          }));
        }
        setCommentText("")
        if (commentList?.items.length > 0 && firstCommentRef.current) {
          firstCommentRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    } catch (error) {
    }
  }
  async function likeComment(id, unlike) {
    try {
      let url;
      if (unlike) {
        url = `/likeComment?commentId=${id}&unlike=true`
      }
      else {
        url = `/likeComment?commentId=${id}`;
      }
      const data = await get(url, getAuthConfig());
      if (data?.status === 200) {
        if (unlike) {
          const updatedItems = commentList?.items.map((item) => {
            if (item?._id === id) {
              return { ...item, likeByYou: false, LikeReplyCount: item?.LikeReplyCount - 1 }
            }
            return item
          })
          setCommentList({ items: updatedItems })
        }
        else {
          const updatedItems = commentList?.items.map((item) => {
            if (item?._id === id) {
              return { ...item, likeByYou: true, LikeReplyCount: item?.LikeReplyCount + 1 }
            }
            return item
          })
          setCommentList({ items: updatedItems })
        }
      }
    } catch (error) {
    }
  }
  async function ReplyListing(commentId) {
    try {
      const data = await get(`/replies?commentId=${commentId}&pageNumber=${ReplyPage}`, getAuthConfig());
      if (data?.status === 200) {

        if (ReplyPage > 1) {
          setReplyList((p) => ({ ...p, items: ReplyList?.items.concat(data?.data?.replies) }));
        } else {
          setReplyList((p) => ({ ...p, items: data?.data?.replies }));
        }
        setReplyList((e) => ({ ...e, has_more: data?.data?.has_more }))
      }
    } catch (error) {
    }
  }
  async function sendAReply() {
    try {
      const formdata = new FormData();
      formdata.append("commentId", selectedComment?.commentID);
      formdata.append("text", selectedComment?.replyText);
      const data = await post(`/replies`, formdata, getAuthConfig());
      if (data?.status === 200) {

        const updatedItems = commentList?.items.map((item) => {
          if (item?._id === selectedComment?.commentID) {
            return { ...item, ReplyCount: item?.ReplyCount + 1 }
          }
          return item;
        })
        setCommentList((p) => ({ ...p, items: updatedItems }))
        // setCommunityData((p) => ({ ...p, commentCount: communityData?.commentCount + 1 }))
        if (data?.data?.reply) {
          setReplyList((p) => ({
            ...p,
            items: [data?.data?.reply, ...ReplyList?.items],
          }));
        }
        setSelectedComment((p) => ({ ...p, replyText: "" }))

      }
    } catch (error) {
    }
  }
  return (
    <Layout>

      <TopBar backlink={"true"} title={"Community Detail"} tab={"Community"} tabChild={"Community Detail"} />
      <div className="main-content-part small-content  blog-inner">
      <section className="blog-inner-page-section pb-0">
          <div className="container">
            <h1 className="blog-inner-page-heading">{communityData?.title}</h1>
            <div className="blog-profile-content">
              <img
                src={
                  communityData?.posted_By?.profile_image &&
                  communityData?.posted_By?.profile_image
                }
                className="profile-image-containerblog"
              />
              <div className="doctor-info-container">
                <p className="doctor-info-card">
                  {" "}
                  {communityData?.posted_By?.name}
                </p>
                <div className="vertical-divider" />
                <p className="doctor-info-card">
                  {moment(communityData?.created_at).format("MMM DD YYYY")}{" "}
                </p>
              </div>
            </div>
            <div className="blog-inner-page-content">
              <div className="blog-inner-page-content-container">
              {communityData?.community_image && <img className="banner img-fluid" src={communityData?.community_image ? communityData?.community_image : BGImage} alt="bg-icon" />}
                <p
                  className="blog-inner-page-content-text-block"
                  dangerouslySetInnerHTML={{
                    __html: communityData?.description,
                  }}
                ></p>
                {/* <div className="card-container" /> */}
              </div>
            </div>
            <hr style={{ padding: "0 35px" }} />
          </div>
        </section>
        <div className="titlePart">
          <div className="likeCount mt-4">
            <div className="textPart">
              <h3 className="text">
                {communityData?.likeCount} Likes
              </h3>
            </div>
            <div className="imagePart">
              {communityData?.likeByYou === true ? 
              
              <img className="image" src={LikeCommentFill} alt='chat' onClick={(e) => {
                e.preventDefault();
                likeCommunity(communityData?._id, "unlike")
              }} />
                :
                <img className="image" src={LikeComment} alt='chat' onClick={(e) => {
                  e.preventDefault();
                  likeCommunity(communityData?._id)
                }} />}
              <img className="image" src={Comment} alt="Comment logo" />
            </div>
          </div>
        </div>
        <div className="commentSection" style={{marginBottom:"120px"}}>
          <h2 className="heading">
            Comments ({communityData?.commentCount})
          </h2>

          {commentList?.items && commentList?.items.length === 0 && <div className="text-center">No Comments Found </div>}
          {commentList?.items && commentList?.items?.map((item, index) => {
            const istTimestamp = moment(item?.created_at).utcOffset('+05:30').format('YYYY-MM-DD HH:mm:ss');

            return (
              <>
              <div ref={index === 0 ? firstCommentRef : null} className="commentPart">
                  <div className="profilePart">
                    <img className="profile" src={item?.user?.profile_image ? item?.user?.profile_image : item?.model_type === "Physiotherapist" ? item?.user?.gender === "female" ? DoctorFeMale : item?.user?.gender === "male" ? DoctorMale : DoctorCommon :
                  item?.user?.gender === "female" ? PatientFemale : item?.user?.gender === "male" ? PatientMale : PatientCommon
                  } style={{ borderRadius: "50%" }} height={"40px"} width={"40px"} alt="Profile" />
                  </div>
                  <div className="textPart">
                    <h3 className="name">
                      {item?.user?.name ? item?.user?.name : item?.user?.name}
                    </h3>
                    <h3 className="comment">
                      {item?.text}
                    </h3>
                    <div className="bottomPart">
                      <div className="leftPart">
                        <h3 className="time">
                          {moment(istTimestamp).format('DD MMM, h:mm A')}
                          {/* 25 Nov, 11:25 AM */}
                        </h3>
                        <div className="LikeReply">
                        <h3 className="LikeCount">
                          {item?.LikeReplyCount} Likes
                        </h3>
                        <h3 className="LikeCount" style={{ cursor: "pointer" }} onClick={(e) => {
                          e.preventDefault();
                          handleShow();
                          setSelectedComment((p) => ({ ...p, commentID: item?._id }));
                          ReplyListing(item?._id)
                        }}>
                          {item?.ReplyCount} Replies
                        </h3>
                        </div>
                      </div>
                      <div className="rightPart">
                        <h3 className="text" style={{ cursor: "pointer" }} onClick={(e) => {
                          e.preventDefault();
                          handleShow();
                          setSelectedComment((p) => ({ ...p, commentID: item?._id }));
                          ReplyListing(item?._id)
                        }}>
                          Reply
                        </h3>
                        {item?.likeByYou === true ? 
                         <img className="image" src={LikeCommentFill} alt='chat' onClick={(e) => {
                          e.preventDefault();
                          likeComment(item?._id, "unlike")                        }} />
                        
                        
                          :
                          <img className="image" src={LikeComment} alt='chat' onClick={(e) => {
                            e.preventDefault();
                            likeComment(item?._id)
                          }} />}
                        {/* <img className="image" src={Like} alt="Like Logo" onClick={(e) =>{
                          e.preventDefault();
                          likeComment(item?._id)
                        }} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          })}

          {commentList?.has_more && <div className="text-center m-4" style={{ cursor: "pointer" }} onClick={(e) => {
            e.preventDefault();
            setCommentPage((prevPageNumber) => prevPageNumber + 1);
          }}>Load More </div>}


        </div>
      </div>
      <div className="bottom-footer">
        <div className="leftPart">
          <img className="ProfileImg" src={user?.profile ? user?.profile : user?.gender === "female" ? PatientFemale : user?.gender === "male" ? PatientMale : PatientCommon} style={{ borderRadius: "55%" }} height={"50px"} width={"40px"} />
        </div>
        <div className="rightPart">
          <div className="form">
            <input className="send" type="text" name="Comment" value={commentText} onChange={(e) => {
              e.preventDefault();
              setCommentText(e.target.value)
            }} 
            onKeyDown={(e) => {
              // Check if Enter key is pressed
              if (e.key === 'Enter') {
                e.preventDefault(); // Prevent default form submission behavior
                if (commentText.trim() !== "") {
                  sendAcomment();
                }
              }
            }} id="CommentArea" placeholder="Write a comment…" />
            <button className="sendButton" onClick={(e) => {
              e.preventDefault();
              if (commentText?.trim() != "") {
                sendAcomment()
              }
            }}>
              <img src={SendButton} alt="Send Button" />
            </button>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Header closeButton className='justify-content-start'>
          <Modal.Title className='text-center'>Reply</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='main-content-part small-content p-0' style={{ height:"fit-content",maxHeight: "fit-content", overflowY: "hidden",overflowX:"hidden"}}> 
            <div className="commentSection">
              {ReplyList?.items && ReplyList?.items?.length === 0 && <div className='text-center'>No Replies Found</div>}
              {ReplyList?.items && ReplyList?.items?.map((item, index) => {
                const istTimestamp = moment(item?.created_at).utcOffset('+05:30').format('YYYY-MM-DD HH:mm:ss');

                return (
                  <>
                    <div className="commentPart">
                      <div className="profilePart">
                        <img className="profile" src={item?.user?.profile_image ? item?.user?.profile_image : item?.model_type === "Physiotherapist" ? item?.user?.gender === "female" ? DoctorFeMale : item?.user?.gender === "male" ? DoctorMale : DoctorCommon :
                  item?.user?.gender === "female" ? PatientFemale : item?.user?.gender === "male" ? PatientMale : PatientCommon
                  } alt="Profile" style={{ borderRadius: "55%" }} height={"50px"} width={"40px"}   />
                      </div>
                      <div className="textPart">
                        <h3 className="name">
                          {item?.user?.name ? item?.user?.name : item?.user?.name}
                        </h3>
                        <h3 className="comment">
                          {item?.text}
                        </h3>
                        <div className="bottomPart">
                          <div className="leftPart">
                            <h3 className="time">
                              {moment(istTimestamp).format('DD MMM, h:mm A')}
                              {/* 25 Nov, 11:25 AM */}
                            </h3>
                          </div>

                        </div>
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
            {ReplyList?.has_more && <div className='text-center m-4' style={{ cursor: "pointer" }} onClick={(e) => {
              e.preventDefault();
              setReplyPage((prevPageNumber) => prevPageNumber + 1)
            }}> Load More</div>}
          </div>
          <div className="bottom-footerModal">
          
            <div className="rightPart">
              <div className="form">
                <input className="send" type="text" name="Comment" value={selectedComment?.replyText} onChange={(e) => {
                  e.preventDefault();
                  setSelectedComment((p) => ({ ...p, replyText: e.target.value }))
                }}   onKeyDown={(e) => {
                  // Check if Enter key is pressed
                  if (e.key === 'Enter') {
                    e.preventDefault(); // Prevent default form submission behavior
                    if (selectedComment?.replyText?.trim() != "") {
                      sendAReply();
                    }
                  }
                }} id="CommentArea" placeholder="Write a reply…" />
                <button className="sendButton" onClick={(e) => {
                  e.preventDefault();
                  if (selectedComment?.replyText?.trim() != "") {
                    sendAReply()
                  }
                }}>
                  <img src={SendButton} alt="Send Button" />
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>

      </Modal>
    </Layout>
  );
};

export default CommunityCardDetails;