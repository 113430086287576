import React, { useCallback, useEffect, useRef } from "react";
import "../../assets/css/home.css";
import Layout from "../../layout";
import DoctorCard from "../../components/DoctorCard";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import LocationIcon from "../../assets/images/location-lcon.svg";
import { useState } from "react";
import { UseEffectOnce } from "../../Hook/UseEffectOnce";
import { get, getAuthConfig } from "../../libs/http-hydrate";
import "../../assets/css/commonComponent.css";
import Star from "../../assets/images/star.svg";
import Comment from "../../assets/images/comment-alt.svg";
import Doctor from "../../assets/images/doctor.png";
import DoctorDummy from "../../assets/images/defaultdoctor@3x.png";
import PatientDummy from "../../assets/images/defaultpatient@3x.png";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { BsSearch } from "react-icons/bs";
import { useLoadScript } from "@react-google-maps/api";
import PlacesAutoComplete from "../AutoComplete";
import Auth from "../../libs/auth";
import DoctorCommon from "../../assets/images/Docotr.png";
import DoctorMale from "../../assets/images/Doctor Male.png";
import DoctorFeMale from "../../assets/images/Doctor Female.png";
import PatientCommon from "../../assets/images/Patint.png";
import PatientFemale from "../../assets/images/Patient Female.png";
import PatientMale from "../../assets/images/Patient male.png";
const AppontmentList = () => {
  const user = Auth?.getCurrentUser();
  const [key, setKey] = useState("anytime");
  const navigate = useNavigate();
  const [doctorList, setDoctorList] = useState({
    items: [],
    has_more: false,
  });
  const [selected, setSelected] = useState(null);
  const [showSidebar, setShowSidebar] = useState(false);
  const handleCloseSidebar = () => setShowSidebar(false);
  const handleShowSidebar = () => setShowSidebar(true);
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countryLoading, setCountryLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [countriesFetched, setCountriesFetched] = useState(false);
  const [country, setCountry] = useState("");
  let [state, setstate] = useState("");
  let [city, setCity] = useState("");
  const [loading, setLoading] = useState(true); // Add a loading state
  const [formData, setFormData] = useState({
    country: "",
    state: "",
    street: "",
    city: "",
    pincode: "",
  });
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDgERv1vmBkeZ0zWVMFRUksVmYao3v5vms",
    libraries: ["places"],
  });
  const [locations, setLocation] = useState(null);

  UseEffectOnce(() => {
    if (user?.loc) {
      const { coordinates } = user.loc;
      const [longitude, latitude] = coordinates;
      if (!(longitude === 0 && latitude === 0)) {
      }
      else{
       // getHomeData();
      }
    }
  }, []);
  useEffect(() => {
    if(selected?.address || selected?.lat){
      getHomeData();
    }
  }, [selected]);
  useEffect(() =>{
    if(page != 0){
      getHomeData()
    }
  },[page])
  UseEffectOnce(() => {
    if (user?.loc) {
      const { coordinates } = user.loc;
      const [longitude, latitude] = coordinates;
      if (!(longitude === 0 && latitude === 0)) {
        getAddressFromCoordinates(latitude, longitude);
      }else{
        fetchLocationFromIP()
      }
    }
    else{
      fetchLocationFromIP()
    }
  }, [user?.loc]);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      // if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && doctorList?.has_more) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [doctorList?.has_more]
  );

  async function getHomeData() {
    try {
      setLoading(true);
      let url = `/physiotherapistlist?pageNumber=${page}`;
  
      if (selected?.lat && selected?.lng) {
        const countryName = await getCountryFromCoordinates(selected.lat, selected.lng);
        url += `&longitude=${selected.lng}&latitude=${selected.lat}&countryBoundry=${countryName}`;
      } else {
        if (formData?.country) {
          url += `&country=${formData.country}`;
        }
        if (formData?.state) {
          url += `&state=${formData.state}`;
        }
        if (formData?.city) {
          url += `&city=${formData.city}`;
        }
      }
  
      const data = await get(url, getAuthConfig());
      if (data?.status === 200) {
        if (page > 0) {
          setDoctorList((p) => ({
            ...p,
            items: doctorList.items.concat(data.data.data),
          }));
        } else {
          setDoctorList((p) => ({ ...p, items: data.data.data }));
        }
        setDoctorList((e) => ({ ...e, has_more: data.data.has_more }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Example: Resetting loading state
    }
  }

  async function getCountryFromCoordinates(lat, lng) {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyDgERv1vmBkeZ0zWVMFRUksVmYao3v5vms`
      );
      const result = response.data.results.find((r) =>
        r.types.includes("country")
      );
      return result ? result.address_components[0].long_name : null;
    } catch (error) {
      console.error("Error fetching country name:", error);
      return null;
    }
  }
  
  async function getCountrie() {
    if (countriesFetched) {
      return; // Countries have already been fetched
    }
    try {
      setCountryLoading(true);
      const data = await get("/country", getAuthConfig());
      setCountries(data?.data?.data);
      setCountriesFetched(true); // Update the state to indicate countries have been fetched
    } catch (error) {
      console.error(error);
    } finally {
      setCountryLoading(false);
    }
  }
  async function handleCountryChange(event) {
    const countryCode = event.target.value;
    setCountry(countryCode);

    if (countryCode) {
      try {
        setStateLoading(true);
        const response = await get(
          `/state?countryCode=${countryCode}`,
          getAuthConfig()
        );
        setStates(response?.data?.data);
        setstate("");
        setFormData((p) => ({ ...p, state: "", city: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setStateLoading(false);
      }
    } else {
      setStates([]);
      setstate("");
    }
  }
  async function handleStateChange(event) {
    const stateCode = event.target.value;
    setstate(stateCode);

    if (country && stateCode) {
      try {
        setCityLoading(true);
        const response = await get(
          `/city?countryCode=${country}&stateCode=${stateCode}`,
          getAuthConfig()
        );
        setCities(response?.data?.data);
        setFormData((p) => ({ ...p, city: "" }));
      } catch (error) {
        console.error(error);
      } finally {
        setCityLoading(false);
      }
    } else {
      setCities([]);
    }
  }
  const getCurrentLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          handleCloseSidebar();
          getAddressFromCoordinates(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  };
  const getAddressFromCoordinates = async (latitude, longitude) => {
    const nominatimUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=18&addressdetails=1`;

    try {
      const response = await axios.get(nominatimUrl, {
        headers: {
          "User-Agent": "YourAppName",
        },
      });

      const addressData = response.data;
      if (addressData && addressData.display_name) {
        const { city,state_district, state, country } = addressData.address;
        setFormData((p) => ({
          ...p,
          city: city || state_district ||  "",
          state: state || "",
          country: country || "",
          street: addressData.display_name,
          country_code:addressData?.address?.country_code?.toUpperCase()
        }));
        setSelected({
          lat: latitude,
          lng: longitude,
          address: addressData?.display_name,
        });
      } else {
        // setAddress("Address not found");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };
  const fetchLocationFromIP = async () => {
    try {
      const response = await axios.get('https://ipapi.co/json/');
      const {
        city,
        region,
        country_name,
        latitude,
        longitude,
        ip,
        country_code
      } = response.data;      
      
      setFormData(prevFormData => ({
        ...prevFormData,
        // city: city || "",
        // state: region || "",
        country: country_name || "",
        country_code: country_code || "", // Add country code to form data
        ip: ip || ""
      }));    

      setSelected({
        address: `${country_name || ''}`.replace(/, , |, ,/g, ', ').trim().replace(/^,|,$/g, ''),
      });
    } catch (error) {
      console.error("Error fetching location from IP:", error);
    }
  };
  return (
    <Layout>
      <div className="main-content-part">
        <div className="top-nav-part">
          <h3><strong>Appointment </strong></h3>
          <div className="d-flex justify-content-between">
            <div className="location-part "></div>

            <div className="location-button-container">
              <button
                className="btn m-3 p-1 align-items-end form-control border "
                style={{ width: "max-content" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleShowSidebar();
                }}
              >
                {" "}
                <img src={LocationIcon} alt="location" /> &nbsp;
                {selected?.address ? selected?.address : "Select Location"}{" "}
              </button>
            </div>
          </div>
        </div>
        <div className="content-block">
          <div className="exercise-tab-part">
            {/* <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              className="mb-3"
            >
              <Tab eventKey="anytime" title="Anytime"> */}
                <Row className="g-4 mt-3">
                  {loading && page === 0 ? (
                    // Full-page loading indicator for the first page
                    <div className="preloader-whirlpool text-center align-items-center">
                      <div className="whirlpool"></div>
                    </div>
                  ) : (
                    <>
                      {doctorList?.items && doctorList?.items?.length <= 0 && (
                        <div className="text-center">
                          No Physiotherapist Found
                        </div>
                      )}

                      {doctorList?.items &&
                        doctorList?.items?.length > 0 &&
                        doctorList?.items.map((item, index) => {
                          return (
                            <>
                              <Col lg="6" ref={lastBookElementRef}>
                                <div className="doctor-card">
                                  <Link
                                    to="/doctorDetails"
                                    state={{ doctor: item }}
                                    className="linkCard"
                                  >
                                    <div className="doctor-card-content">
                                      <div className="image-part">
                                        {item?.profile_image ? (
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                            }}
                                          >
                                            <img
                                              src={item?.profile_image}
                                              style={{ borderRadius: "55%" }}
                                              height={100}
                                              width={100}
                                              alt="User Profile"
                                            />
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              position: "relative",
                                              display: "inline-block",
                                            }}
                                          >
                                            <img
                                              src={
                                                item?.gender === "female"
                                                  ? DoctorFeMale
                                                  : item?.gender === "male"
                                                  ? DoctorMale
                                                  : DoctorCommon
                                              }
                                              style={{ borderRadius: "55%" }}
                                              height={100}
                                              width={100}
                                              alt="User Profile"
                                            />
                                          </div>
                                        )}{" "}
                                      </div>
                                      <div className="text-part">
                                        <h5 className="name d-flex">
                                          {item?.name}{" "}
                                          {(item?.clinicVerified === true || item?.adminBadgeVerified === true)  && ( // Check if the user is verified
                                            <div
                                              className="px-1"
                                              // style={{
                                              //     position: 'absolute',
                                              //     top: '-2px',
                                              //     right: '-5px',
                                              // }}
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="20"
                                                height="20"
                                                viewBox="0 0 20 20"
                                              >
                                                <circle
                                                  cx="10"
                                                  cy="10"
                                                  r="10"
                                                  fill="green"
                                                />
                                                <path
                                                  d="M7 10 L9 12 L14 7"
                                                  stroke="white"
                                                  stroke-width="2"
                                                  fill="transparent"
                                                />
                                              </svg>

                                              {/* <img src={VerificationBadge} width="20" height="20" alt="Verification Badge" /> */}
                                            </div>
                                          )}
                                        </h5>{" "}
                                        <h6>{item?.speciality}</h6>
                                        <p>
                                          {item?.years_of_experience} yrs of
                                          exp. overall
                                        </p>
                                        {item?.address && (
                                          <p>
                                            {item?.address?.street} , {item?.address?.city} , {item?.address?.state} , {item?.address?.country}
                                          </p>
                                        )}
                                        <div className="rating">
                                          <p>
                                            <img
                                              src={Comment}
                                              alt="comment alt"
                                            />{" "}
                                            {item?.patientCount} Patient Stories
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="doctor-rating-part">
                                      <div className="rating-block">
                                        <p>
                                          <img src={Star} alt="star" />{" "}
                                          {item?.averageRating.toFixed(1)} (
                                          {item?.totalRatingCount})
                                        </p>
                                      </div>
                                      <div className="book-now">
                                        <button>Book Now</button>
                                      </div>
                                    </div>
                                  </Link>

                                  {item?.associatedEntity === "Individual" && (
                                    <div className="hospital-details">
                                      <span className="divider"></span>
                                      <p>{"Individual"}</p>
                                      {item?.fees && (
                                        <p>
                                          {item?.currency === "USD" || item?.currency === "CAD" ? "$" : item?.currency === "INR" ? "₹" : item?.currency === "EUR" ? "€" :""} {item?.fees} Consultation
                                          Fees
                                        </p>
                                      )}
                                    </div>
                                  )}
                                  {item?.associatedEntity?.name && (
                                    <div
                                      className="hospital-details"
                                      style={{ cursor: "pointer" }}
                                      onClick={(e) => {
                                        navigate("/clinicPhysiotherapist", {
                                          state: {
                                            clinic: item?.associatedEntity,
                                          },
                                        });
                                      }}
                                    >
                                      <span className="divider"></span>
                                      <p>{item?.associatedEntity?.name}</p>
                                      {item?.hospital_fees && (
                                        <p>
                                          {item?.currency === "USD" || item?.currency === "CAD" ? "$" : item?.currency === "INR" ? "₹" : item?.currency === "EUR" ? "€" :""}  {item?.fees} Consultation
                                          Fees
                                        </p>
                                      )}
                                      <p>
                                        {item?.associatedEntity?.address?.state}{" "}
                                        ,{" "}
                                        {
                                          item?.associatedEntity?.address
                                            ?.country
                                        }
                                      </p>
                                    </div>
                                  )}
                                  {/* <div className="hospital-details">
                                <span className="divider"></span>
                                <p>{item?.hospital_name}</p>
                                {item?.hospital_fees && <p>${item?.hospital_fees} Consultation Fees</p>}
                              </div> */}
                                  {item?.fees && (
                                    <div className="bottom-bar light-bg">
                                      <p>{item?.currency === "USD" || item?.currency === "CAD" ? "$" : item?.currency === "INR" ? "₹" : item?.currency === "EUR" ? "€" :""} {item?.fees} Consultation fees</p>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </>
                          );
                        })}
                    </>
                  )}

                  {loading && page > 0 && (
                    <div className="preloader-whirlpool text-center align-items-center">
                    <div className="whirlpool"></div>
                    </div>
                  )}
                </Row>
              {/* </Tab> */}
              {/* <Tab eventKey="video-consult" title="Video consult">
                <Row className="g-4">
                  <Col lg="6">
                    <Link to="/doctorDetails" className="linkCard">
                      <DoctorCard />
                    </Link>
                  </Col>
                  <Col lg="6">
                    <Link to="/" className="linkCard">
                      <DoctorCard />
                    </Link>
                  </Col>
                  <Col lg="6">
                    <Link to="/" className="linkCard">
                      <DoctorCard />
                    </Link>
                  </Col>
                  <Col lg="6">
                    <Link to="/" className="linkCard">
                      <DoctorCard />
                    </Link>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="plus" title="Plus">
                <Row className="g-4">
                  <Col lg="6">
                    <Link to="/doctorDetails" className="linkCard">
                      <DoctorCard />
                    </Link>
                  </Col>
                  <Col lg="6">
                    <Link to="/" className="linkCard">
                      <DoctorCard />
                    </Link>
                  </Col>
                </Row>
              </Tab> */}
            {/* </Tabs> */}
          </div>
        </div>
      </div>

      {/* Add Note Filter  */}
      <Modal
        show={showSidebar}
        onHide={handleCloseSidebar}
        className="modal-sidebar"
        size="md"
      >
        <Modal.Header
          className="bg-light p-4 m-0"
          style={{ display: "inline-grid", textAlign: "left" }}
        >
          <span style={{ fontSize: "14px" }}>
            Select a location to find a Doctors
          </span>
          <span style={{ fontSize: "12px" }}>
            Choose your address location to see a doctor availibility and
            appointment options
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="body-content px-4">
            {/* <select
              className=" form-control form-select w-100"
              style={{ width: "max-content" }}
              value={country}
              onChange={(e) => {
                setPage(0);
                handleCountryChange(e);
                const selectedCountry = countries.find(
                  (c) => c.isoCode === e.target.value
                );
                if (selectedCountry) {
                  setFormData((prevData) => ({
                    ...prevData,
                    country: selectedCountry.name,
                  }));
                }
              }}
              onClick={(e) => {
                getCountrie();
              }}
            >
              <option value="">
                {formData?.country ? formData?.country : "Select Country"}
              </option>
              {countryLoading ? (
                <option disabled>Loading...</option>
              ) : (
                countries.map((country) => (
                  <option key={country?.code} value={country?.isoCode}>
                    {country?.name}
                  </option>
                ))
              )}
            </select>

            <select
              className="my-2 form-control form-select w-100"
              style={{ width: "max-content" }}
              value={state}
              onChange={(e) => {
                setPage(0);
                handleStateChange(e);
                const selectedStates = states.find(
                  (c) => c.isoCode === e.target.value
                );
                if (selectedStates) {
                  setFormData((prevData) => ({
                    ...prevData,
                    state: selectedStates.name,
                  }));
                }
              }}
            >
              <option value="">
                {formData?.state ? formData?.state : "Select State"}
              </option>
              {stateLoading ? (
                <option disabled>Loading...</option>
              ) : (
                states.map((state) => (
                  <option key={state?.code} value={state?.isoCode}>
                    {state?.name}
                  </option>
                ))
              )}
            </select>
            <select
              className="my-2 form-control form-select w-100"
              style={{ width: "max-content" }}
              value={formData?.city}
              onChange={(e) => {
                setPage(0);
                setFormData((p) => ({ ...p, city: e?.target?.value }));
              }}
            >
              <option value="">
                {formData?.city ? formData?.city : "Select City"}
              </option>

              {cityLoading ? (
                <option disabled>Loading...</option>
              ) : (
                cities.map((city) => (
                  <option key={city?.code} value={city?.code}>
                    {city?.name}
                  </option>
                ))
              )}
            </select>
 */}

            <button
              className="btn btn-them mb-2"
              style={{
                fontWeight: "unset",
                fontSize: "14px",
                borderRadius: "6px",
              }}
              onClick={(e) => {
                e.preventDefault();
                getCurrentLocation();
                setPage(0);
              }}
            >
              Use Current Location
            </button>
            <div className="action-part">
              <div className="divider">
                <span className="line"></span>
                <p className="text">OR</p>
              </div>
            </div>

            <PlacesAutoComplete
              setPage={setPage}
              setFormData={setFormData}
              handleCloseSidebar={handleCloseSidebar}
              selected={selected}
              setSelected={setSelected}
              isLoaded={isLoaded}
              country={formData?.country_code}
            />
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default AppontmentList;
